.login-form {

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    input {
        border: 1px solid black;
        border-radius: 1rem;

        background: white;

        font-size: .65rem;
        padding: .5em;

        outline: none;
        width: calc(100% - 1em);
    }

    label {
        display: block;
        width: 100%;
        margin: 2rem 0 3.25rem;
    }

    .error {
        color: #e2403e;
    }
}

@import '../../variables.scss';

.layout-box {

    max-width: 1256px;
    width: 95%;

    margin: 0 auto 1rem;
    padding: 1rem 0;

    background: $box-background-color;

    position: relative;

    box-shadow: 0px 0px 29px 13px rgba(0,0,0,.35);

    &.bg-white {
        background: white;
    }
}

.home-page {
    h1 {
        margin: 0;
    }

    .content {
        max-width: 850px;
        width: 95%;
        margin: 0 auto;

        font-family: 'Alegreya Sans SC', sans-serif;

        strong {
            font-weight: 500;
        }
    }

    p {
        text-align: center;
    }

    nav {
        display: flex;
        justify-content: center;
    }
}

.task-01 {
    .song {
        position: relative;
        background: linear-gradient(0deg, #c3a174 0%, #e8c59b 14.8%, #e8c59b 82.6%, #c3a174 100%);

        width: calc(100% - 9rem);
        max-width: calc(760px - 4em);
        padding: 1em 2em;
        margin: 0 auto 3em;

        font-size: 0.8rem;

        border: 1px solid black;
        line-height: 1.2em;

        &:after {
            content: "";
            position: absolute;
            background: url("../../../../assets/task-01/pieczec.svg") 50% 50% no-repeat;
            background-size: contain;

            position: absolute;

            width: 4rem;
            height: 5rem;

            right: -2rem;
            bottom: -3rem;
        }

        strong {
            font-weight: normal;
        }
    }

    .task-buttons {
        @media (max-width: 700px) {
            width: 88%;

            & > *:not(.spacer) {
                flex: 0 0 44%;
            }
        }
    }
}

.task-01-game {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 0.8rem;

    .item {
        flex: 0 1 44%;

        @media (max-width: 700px) {
            flex: 0 1 100%;
        }
    }

    .content {
        background: linear-gradient(
            90deg,
            #ffb302 0%,
            #ffba01 4%,
            #ffba01 18%,
            #ffdf01 35%,
            #ffdf01 62%,
            #fec300 83%,
            #fec300 93%,
            #ffb302 100%
        );

        border-radius: 48px;
        padding: 6px;

        text-align: center;

        font-family: "Alegreya Sans SC", sans-serif;

        .inner {
            background: white;
            border-radius: 42px;

            padding: 1em;
            min-height: 6em;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .buttons {
        width: 88%;
        margin: 1em auto;

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & > * {
            flex: 0 0 44%;
        }
    }
}

.task-05 {
    .game-intro {
        ul {
            text-align: left;
            list-style: none;
            strong {
                color: #333333;
            }

            li {
                text-indent: .5em;
                line-height: 1.55em;

                &:before {
                    content: "\25AA";
                    color: #8c2920;
                    font-weight: bold;
                    display: inline-block;
                    width: 1em;
                }
            }
        }
    }
}

.page-footer {
    padding-bottom: 1rem;


    .layout-box {
        padding-top: 0;
    }

    .container {
        margin: 0 auto;
        max-width: 1035px;
        width: 95%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    h3 {
        text-transform: uppercase;
        font-weight: normal;
        margin: .5em 0 1em;
        font-size: .8rem;
    }

    p {
        text-align: center;
        font-size: .8rem;
        margin: .7em;
    }
}

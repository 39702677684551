@keyframes zooming {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

.map {
    .generator-link {
        position: absolute;

        top: 2%;
        left: 50%;

        a {
            margin-left: -50%;
        }
    }

    .buttons {
        position: fixed;

        bottom: 2%;
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .task-link {
        display: block;
        position: absolute;
        width: 5%;
        padding-top: 5%;

        border-width: 2px;
        border-style: solid;
        border-color: #ffcf00;
        border-radius: 50%;

        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 120%;
            height: 120%;
            left: -10%;
            top: -10%;
            border-radius: 100%;
        }

        &.avaiable {
            &:before {
                box-shadow: 0px 0px 5px 1px rgba(255, 198, 0, 0.72);
            }

            &:after {
                box-shadow: inset 0px 0px 5px 1px rgba(255, 198, 0, 0.72);
            }
        }

        &.completed {
            border: none;
            &:before {
                box-shadow: none;
            }

            &:after {
                box-shadow: none;
            }
        }
    }

    .task-link-02 {
        top: 24.4%;
        left: 11.5%;
    }

    .task-link-01 {
        top: 37.5%;
        left: 24%;
    }

    .task-link-07 {
        top: 34.5%;
        left: 34.2%;
    }

    .task-link-03 {
        top: 29.5%;
        left: 39.5%;
    }

    .task-link-08 {
        top: 25.5%;
        left: 55.5%;
    }

    .task-link-09 {
        top: 17.5%;
        left: 61%;
    }

    .task-link-05 {
        top: 67.5%;
        left: 67.8%;
    }

    .task-link-04 {
        top: 28.5%;
        left: 80.3%;
    }

    .task-link-06 {
        top: 22.5%;
        left: 85.3%;
    }

    .task-link-10 {
        top: 11.5%;
        left: 52%;
    }
}

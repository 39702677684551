@import "../../../../variables.scss";

.task-06 {
    .questions {
        list-style: none;
        counter-reset: li;
        margin: 1.25em 0;

        li {
            counter-increment: li;
            color: #010101;
            margin-bottom: 0.5em;

            &:before {
                content: counter(li) ".";
                font-weight: bold;
                font-size: 1.125em;
                color: $color-3;
                display: inline-block;
                margin-right: 0.15em;
            }
        }
    }

    .cw-container {
        position: relative;
    }

    .cw-cell {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;

        &.extra-border-left {
            &:before {
                content: "";
                display: block;

                width: 4px;
                height: 100%;
                background: black;

                position: absolute;
                top: 0;
                left: 0;

                transform: translate(-3px, 0);
            }
        }

        &.extra-border-right {
            &:before {
                content: "";
                display: block;

                width: 4px;
                height: 100%;
                background: black;

                position: absolute;
                top: 0;
                right: 0;

                transform: translate(3px, 0);
            }
        }

        .pencil-mark {
            position: absolute;
            top: 0;
            left: 0.2em;
            font-size: 0.5em;
        }
    }

    .cell-label {
        text-align: center;
        border: 1px solid $color-3;
        background: rgba(223, 212, 192, 1);
        border-radius: 10px;

        color: $color-1;

        span {
            display: block;
            width: 90%;
            height: 90%;
        }
    }

    .cell-input {
        background: white;
        border: 1px solid black;
        border-radius: 10px;

        &.correct {
            background: $correct-color;
        }

        &.wrong {
            background: $wrong-color;
        }

        &.marked {
            background: yellow;
        }

        input {
            display: block;
            width: 90%;
            height: 90%;

            padding: 0;

            background: transparent;
            border: none;
            text-align: center;

            font-family: "citrus-gothic", "Inter", sans-serif;
            color: $color-3;
            font-size: 0.7em;
            line-height: 0.7em;
        }
    }

    .info {
        display: flex;

        align-items: flex-end;

        .questions {
            flex: 0 0 60%;
        }

        .buttons {
            flex: 0 0 40%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
        }
    }
}

@import '../../variables.scss';

.login-page {
    .container {
        width: 95%;
        max-width: 1040px;
        margin: 0 auto;

        padding-bottom: 2rem;
    }
}

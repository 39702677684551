@import "./variables.scss";

@import-normalize;

body {
    height: 100%;
}

html {
    height: 100%;
    min-width: 320px;
}

html,
body,
div,
p,
a,
img,
li,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

html {
    font-size: 42px;

    background: url("./assets/background.png") 50% 50% no-repeat;
    background-size: cover;
    background-attachment: fixed;

    font-family: 'Alegreya Sans', sans-serif;

    @media (max-width: 900px) {
        font-size: 32px;
    }

    @media (max-width: 600px) {
        font-size: 24px;
    }
}

body {
    color: $font-color;
    font-weight: 400;

    &.no-image {
        background: $body-background-color;
    }
}

#root {
    min-height: 100%;
    height: 100%;
    position: relative;
}

h1 {
    font-size: 2.4rem;
    color: $header-color;
    font-weight: normal;
    text-align: center;

    font-family: 'Alegreya Sans SC', sans-serif;
}

h2 {
    font-size: 1.6rem;
    color: $header-color;
    font-weight: normal;
    margin: 0.5rem 0;
    font-family: 'Alegreya Sans SC', sans-serif;
    text-align: center;
}

p {
    margin: 1.3em 0;
}

.page {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

@import '../../../../variables.scss';

.task-xx {
    .group {
        display: flex;

        &:nth-child(2n) {
            justify-content: flex-end;
        }

        margin: 1rem 0;
    }

    .group-item {
        padding: 0.5rem;
        cursor: pointer;
        border-radius: 1rem;

        width: 9rem;

        img {
            width: 100%;
        }
    }

    .selected {
        background-color: $wrong-color;
    }

    .selected.correct {
        background-color: $correct-color;
    }
}

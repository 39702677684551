.task-04 {
}

.task-04-game {
    display: flex;
    flex-direction: column;

    justify-content: center;

    textarea {
        border: solid #4D597C 3px;
        padding: 1em;
        border-radius: 1em;
        height: 25vh;
        font-size: .8rem;

        font-family: 'Alegreya Sans', sans-serif;
        outline: none;
    }

    .button {
        width: 100%;
        display: flex;
        justify-content: center;

        &>* {
            width: 50%;
            max-width: 380px;
        }

        margin: 1rem auto 2rem
    }
}

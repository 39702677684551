.task-09 {
    .video-container {
        width: 100%;
        padding-top: 56%;
        position: relative;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .song {

        margin: 2rem auto;
        line-height: 1.5em;

        h2 {
            font-family: "Alegreya Sans", sans-serif;
            font-size: 1rem;
            font-weight: bold;
            text-align: left;
            color: #333333;
            margin-bottom: 1.5rem;
        }

        input {
            font-family: "Alegreya Sans", sans-serif;
            display: inline-block;
            font-size: .9em;
            height: 1em;
            border-radius: 1em;
            border: 1px solid black;
            padding: 0 .5em;
            width: 5em;
            text-align: center;
        }
    }
}

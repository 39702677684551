$color-1: #643726;
$color-2: rgba(223, 212, 192, .84);
$color-3: #8c2920;
$color-4: #333333;
$color-5: #9C9B45;
$color-6: #E15343;
$color-7: #594c32;


$body-background-color: $color-2;

$box-background-color: $color-2;

$header-color: $color-7;
$font-color: $color-4;
$task-header-color: $color-3;

$button-text-color: $color-2;

$correct-color: $color-5;
$wrong-color: $color-6;

$text-box-background: $color-7;

.task-02 {
}

.task-02-pick-up {
    display: block;
    width: 100%;
}

.task-02-game {
    .baskets {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        img {
            width: 100%;
        }

        .basket {
            flex: 0 0 23%;
            min-width: 23%;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &.wrong {
                .placeholder {
                    background: #cc2828;
                    color: white;
                }
            }
        }

        .drop-zone {
            height: 4rem;
            margin-top: 1.3rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    .item-pool {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        margin: 1rem 0 2rem;

        .pick-up {
            width: 100%;
        }

        .item-in-pool {
            flex: 0 0 23%;
            min-width: 23%;
            margin-bottom: 0.25rem;
        }
    }

    .placeholder {
        border: 1px solid black;
        width: 100%;
        line-height: 1em;
        padding: 1.5em 0;
        background: #f2f2f2;
        color: #808080;
        text-transform: uppercase;

        font-size: 0.4rem;
        text-align: center;

        border-radius: 1.2em;

        transition: all 1s;
    }

    .game-item {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        color: white;
        text-align: center;
        font-family: "Alegreya Sans SC", sans-serif;
        font-size: 0.6rem;

        background: linear-gradient(
            90deg,
            #ffb302 0%,
            #ffba01 4%,
            #ffba01 18%,
            #ffdf01 35%,
            #ffdf01 62%,
            #fec300 83%,
            #fec300 93%,
            #ffb302 100%
        );

        border-radius: 0.9em;
        padding: 0.3em;
        position: relative;

        .inner {
            height: 2.5em;

            background: linear-gradient(0deg, #6f7b9d 0%, #323e60 16%, #323e60 84%, #6f7b9d 100%);
            border-radius: 0.8em;
            box-shadow: inset 0px 0px 1px 1px #3e4862;

            // :hover {
            //     background: linear-gradient(0deg, #323e60 0%, #6f7b9d 16%, #6f7b9d 84%, #323e60 100%);
            // }

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;
            padding: 0 2%;
            max-width: 96%;

            overflow: hidden;
        }
    }
}

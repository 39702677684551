.task-07 {
    .game-container {
        &.hide {
            .brackets {
                padding-top: 0;
            }
        }
    }
}

.task-07-game {
    margin: 0 auto;

    .bracket-container {
        width: calc(100% - 1em);
        margin: 0 auto;

        background: white;
        border: 1px solid black;
        border-radius: 0.5em;
        padding: 0.5em;
        margin-bottom: 1em;
        font-size: 0.75rem;

        font-family: "Alegreya Sans SC", sans-serif;

        &.correct {
            box-shadow: 0 0 20px green;
        }

        &.wrong {
            box-shadow: 0 0 20px red;
        }

        &.hide {
            opacity: 0;
        }
    }

    .brackets {
        transition: all 1s;

        position: relative;
        width: 100%;
    }

    .brackets-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
    }

    .bracket {
        position: absolute;
        width: 100%;
        top: 0;
        pointer-events: none;
        opacity: 0;
        transition: all 1s;

        display: flex;
        justify-content: center;
        align-items: center;

        &.active {
            pointer-events: all;
            opacity: 1;
        }

        p {
            margin: 0;
            padding: 0;
        }

        img {
            display: block;
            width: 100%;
            transition: all 1s;
        }
    }

    .image-items {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 800px;
        margin: 0 auto;

        .item-in-pool {
            flex: 0 0 30%;
        }

        img {
            width: 100%;
            pointer-events: none;
        }
    }

    .text-items {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 800px;
        margin: 0 auto;

        .item-in-pool {
            flex: 0 0 30%;
            min-width: 0;
        }
    }
}

.task-07-pick-up-image {
    width: 100%;

    img {
        width: 100%;
    }
}

.task-07-pick-up-text {
    width: 100%;
    font-size: 0.7em;

    .container {
        background: linear-gradient(
            90deg,
            #ffb302 0%,
            #ffba01 4%,
            #ffba01 18%,
            #ffdf01 35%,
            #ffdf01 62%,
            #fec300 83%,
            #fec300 93%,
            #ffb302 100%
        );

        border-radius: 10px;
        padding: 6px;

        text-align: center;

        font-family: "Alegreya Sans SC", sans-serif;

        .inner {
            background: white;
            border-radius: 8px;

            padding: 1em;
            min-height: 3.5em;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}

.task-03 {
}

.task-03-game {
    width: 95%;
    max-width: 1075px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    margin-bottom: 1.5em;

    .column {
        display: flex;
        flex-direction: column;
        flex: 0 0 42%;

        align-items: stretch;

        &.group-2 {
            .inner {
                background: white;

                button {
                    color: black;
                }
            }
        }
    }

    .item-container {
        display: inline-block;
        margin-bottom: 0.5em;

        background: linear-gradient(0deg, #6f7b9d 0%, #323e60 100%);

        border-radius: 0.45em;
        padding: 0.07em;
        position: relative;

        transition: all 1s;

        .inner {
            background: linear-gradient(0deg, #323e60 0%, #6f7b9d 100%);
            border-radius: 0.4em;
            box-shadow: inset 0px 0px 1px 1px #626e90;

            // &:hover {
            //     background: linear-gradient(0deg, #b10d0b 0%, #e2403e 16%, #e2403e 84%, #be1b17 100%);
            // }

            display: flex;
            flex-direction: column;
            align-items: stretch;
        }

        button {
            background: none;
            border: none;
            font-family: "Alegreya Sans", sans-serif;
            cursor: pointer;

            color: white;
            text-decoration: none;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 0.75em;
            letter-spacing: 0.15em;
            display: block;
            line-height: 1.5em;
            text-align: center;
        }

        &.selected {
            background: linear-gradient(
                0deg,
                #ffb302 0%,
                #ffba01 6%,
                #ffba01 28%,
                #ffdf01 50%,
                #fec300 67%,
                #fec300 89%,
                #ffb302 100%
            );

            .inner {
                box-shadow: inset 0px 0px 1px 1px #5a5650;
            }
        }

        &.correct {
            background: linear-gradient(0deg, #6bce57 0%, #4e910f 16%, #4e910f 84%, #6bce57 100%);

            .inner {
                box-shadow: inset 0px 0px 1px 1px #394f53;
            }
        }

        &.wrong {
            background: linear-gradient(0deg, #b10d0b 0%, #cc2828 16%, #cc2828 84%, #b10d0b 100%);

        }
    }
}

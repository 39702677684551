@import "../../variables.scss";

.pre-event-page {
    width: 95%;
    max-width: 850px;
    margin: 5rem auto;
    padding-top: 3rem;

    box-shadow: 0px 0px 29px 13px rgba(0, 0, 0, 0.35);
    background: $box-background-color;

    position: relative;

    &:before {
        content: "";
        width: 5rem;
        height: 3.5rem;

        position: absolute;

        background: url("../../assets/herb.png") 50% 50% no-repeat;
        background-size: contain;
        top: -2rem;
        width: 100%;
    }

    h1 {
        color: $color-3;
        font-size: 2rem;
        font-weight: 500;
    }

    .text {
        width: 95%;
        margin: 0 auto;
        text-align: center;

        p {
            font-size: 1.5rem;
            margin-top: 1rem;
        }

        strong {
            color: $color-3;
        }
    }
}

@import '../../variables.scss';

.end-game-page {
    text-align: center;

    .buttons {
        margin: 0 auto;
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        &>* {
            width: 80%;
            max-width: 500px;
        }
    }

    .content {
        width: 95%;
        max-width: 900px;
        margin: 0 auto;

        a {
            color: $color-3;
        }
    }

    strong {
        font-family: 'Alegreya Sans SC', sans-serif;
    }

    .type-2 {
        font-family: 'Alegreya Sans SC', sans-serif;
    }

    .right {
        text-align: right;
    }
}

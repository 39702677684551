@import "../../variables.scss";

.rules-page {
    .rules-box {
        overflow: hidden;
    }

    .container {
        width: 95%;
        max-width: 860px;
        margin: 0 auto;
    }

    .buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 2rem 0 .5rem;
    }

    ol {
        list-style: none;
        counter-reset: li;
        margin: 1.25em 0 3.5em;
    }

    li {
        counter-increment: li;
        color: #010101;
        margin-bottom: 0.5em;

        &:before {
            content: counter(li) ".";
            font-weight: bold;
            font-size: 1.125em;
            color: #594c32;
            display: inline-block;
            width: 0.75em;
        }
    }

    p {
        font-family: "Alegreya Sans SC", sans-serif;
    }
}

@import '../../variables.scss';

.task {
    min-height: 80vh;
    width: 100%;

    .task-header {
        display: flex;
        flex-direction: row;

        width: 95%;
        margin: 0 auto;

        align-items: stretch;

        .left {
            flex: 0 0 30%;
            padding-top: 0.5rem;

            .img {
                width: 100%;
                padding-top: 136%;

                background-size: contain;
                background-repeat: no-repeat;
            }
        }

        .right {
            padding: 0 0 0 0.5em;

            display: flex;
            flex-direction: column;

            h1 {
                font-size: 1.375rem;
                text-align: left;
                padding-left: 0.3em;
            }

            .quote-container {
                flex: 1 1 100%;
                font-size: 0.8rem;

                // display: flex;
                // flex-direction: column;
                // justify-content: center;

                $qHeight: 2.6em;
                margin-top: $qHeight;

                .spacer {
                    width: 10px;
                    // padding: $qHeight/2 0 0;
                    float: right;
                }

                .q-begin {
                    height: $qHeight;
                    width: $qHeight * 1.5;
                    margin-top: -$qHeight * 0.2;

                    background: url("../../assets/q-up.png") 50% 50% no-repeat;
                    background-size: contain;

                    box-sizing: border-box;
                    float: left;
                    clear: left;
                }

                .q-end {
                    height: $qHeight;
                    width: $qHeight * 1.5;
                    // margin-top: -$qHeight * 0.1;

                    position: relative;

                    background: url("../../assets/q-down.png") 50% 50% no-repeat;
                    background-size: contain;

                    box-sizing: border-box;
                    float: right;
                    clear: right;
                }

                .quote {
                    position: relative;
                    text-align: center;

                    font-family: "Alegreya Sans SC", sans-serif;
                    font-weight: 500;

                    margin-top: $qHeight / 2;

                    &::first-line {
                        text-align: left;
                    }
                }
            }
        }
    }

    .persona-info {
        width: 85%;
        margin: 0 auto;
        text-align: center;

        strong {
            font-family: "Alegreya Sans SC", sans-serif;
        }
    }

    .task-widget {
        width: 95%;
        margin: 0 auto;
        max-width: 1140px;
    }

    .complete-button {
        display: flex;
        justify-content: flex-end;

        margin: 3rem 0;
    }

    .game-intro {

        h2 {
            color: $task-header-color
        }

        .type-2 {
            font-family: "Alegreya Sans SC", sans-serif;
        }

        text-align: center;

        strong {
            color: $color-3;
            font-weight: 500;
            font-family: "Alegreya Sans SC", sans-serif;
        }
    }
}

.task-buttons {
    width: 95%;
    max-width: 1075px;
    margin: 2rem auto 0;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    &>*:not(.spacer) {
        flex: 0 0 40%;
    }
}

.task-08 {
    .game-container {

        &.hide {
            .task-08-game {
                padding-top: 0;
            }
        }
    }
}

.task-08-game {
    transition: all 1s;
    position: relative;
    padding-top: 75%;

    .item {
        display: flex;
        justify-content: space-between;
        margin: 1rem 0;

        position: absolute;
        top: 0;
        width: 100%;
        opacity: 0;
        pointer-events: none;
        transition: all 1s;

        &.active {
            opacity: 1;
            pointer-events: all;
        }
    }

    .left {
        display: flex;
        flex: 0 0 48%;
        align-items: flex-start;

        img {
            width: 100%;
            display: block;
        }
    }

    .right {
        display: flex;
        flex: 0 0 48%;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .option {
            width: 80%;
            display: flex;
            flex-direction: column;
        }
    }
    .item-container {
        display: inline-block;
        margin-bottom: 0.5em;

        background: linear-gradient(0deg, #6f7b9d 0%, #323e60 100%);

        border-radius: 0.45em;
        padding: 0.07em;
        position: relative;

        .inner {
            background: white;
            border-radius: 0.4em;
            box-shadow: inset 0px 0px 1px 1px #626e90;

            // &:hover {
            //     background: linear-gradient(0deg, #b10d0b 0%, #e2403e 16%, #e2403e 84%, #be1b17 100%);
            // }

            display: flex;
            flex-direction: column;
            align-items: stretch;
        }

        button {
            background: none;
            border: none;
            font-family: "Alegreya Sans SC", sans-serif;
            cursor: pointer;

            color: black;
            text-decoration: none;
            font-weight: 500;
            font-size: 0.75em;
            letter-spacing: 0.15em;
            display: block;
            line-height: 1.5em;
            text-align: center;
        }

        &:hover {
            background: linear-gradient(
                0deg,
                #ffb302 0%,
                #ffba01 6%,
                #ffba01 28%,
                #ffdf01 50%,
                #fec300 67%,
                #fec300 89%,
                #ffb302 100%
            );

            .inner {
                box-shadow: inset 0px 0px 1px 1px #5a5650;
            }
        }

        &.correct {
            background: linear-gradient(0deg, #6bce57 0%, #4e910f 16%, #4e910f 84%, #6bce57 100%);

            .inner {
                box-shadow: inset 0px 0px 1px 1px #394f53;
            }
        }

        &.wrong {
            background: linear-gradient(0deg, #b10d0b 0%, #cc2828 16%, #cc2828 84%, #b10d0b 100%);

            .inner {
                box-shadow: inset 0px 0px 1px 1px #f1d2d1;
            }

        }
    }
}
